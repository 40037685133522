import React, { Fragment } from "react";
import CvEntry from "./CvEntry";

export default function Education(props) {
  return (
    <Fragment>
      <h2>Educational history and certifications</h2>
      <CvEntry
        date="08/2018"
        name="iSAQB Certified Professional for Software Architecture - Advanced "
      />
      <CvEntry date="03/2018" name="TOGAF 9 Foundation level certification " />
      <CvEntry
        date="10/2016"
        name="iSAQB Certified Professional for Software Architecture - Foundation "
      />
      <CvEntry
        date="08/2012"
        name="Accenture - Certified Technology Architect"
      />
      <CvEntry
        date="08/2011"
        name="Oracle Java EE 5 Web Component Developer (former SCWCD)"
      />
      <CvEntry date="11/2009" name="Sun Certified Java Programmer (SCJP)" />
      <CvEntry
        date="10/2001 - 09/2008"
        name="Studies in Computer Science (Diploma)"
        location="Eberhard-Karls-Universität, Tübingen, Germany"
      >
        <p>Minor subject: Mathematics</p>
        <p>
          Diploma Thesis: „
          <i>
            A XMPP-based communication library for the Peer-to-Peer Grid
            Computing
          </i>
          “ using JAVA J2SE, Swing, JSP, JUnit, XML, yFiles.
        </p>
        <p>Overall average grade: 1 (German)</p>
      </CvEntry>
      <CvEntry
        date="02/2005 - 11/2005"
        name="Study Abroad"
        location="Queensland University of Technology, Brisbane, Australia"
      >
        <p>Two semesters abroad Overall average grade: 7 (Australian)</p>
      </CvEntry>
    </Fragment>
  );
}
