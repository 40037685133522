import React, { Fragment } from "react";
import CvEntry from "./CvEntry";

export default function Teaching(props) {
  return (
    <Fragment>
      <h2>Teaching experience</h2>
      <CvEntry
        date="06/2010 - 08/2013	"
        name="Trainer for Java User Interfaces with Accenture new joiners "
      />
      <CvEntry
        date="10/2007 - 02/2008"
        name="Tutor Computer Science III (theoretical computer science) "
      />
      <CvEntry
        date="10/2006 - 02/2007"
        name="Tutor Mathematics I for Biologists and Geoecologists (MATLAB)"
      />
      <CvEntry
        date="04/2004 - 07/2004"
        name="Tutor Algorithms and Complexity II"
      />
      <CvEntry
        date="05/2003 - 07/2003"
        name="Tutor Computer Science II (MMIX Assembler-Programing)"
      />
    </Fragment>
  );
}
