import React, { Fragment } from "react";
import CvEntry from "./CvEntry";

export default function Publications(props) {
  return (
    <Fragment>
      <h2>Publications</h2>
      <CvEntry
        date="2010"
        name={<Fragment>S. Schulz, W. Blochinger, and M. Poths. <i>Orbweb - A Network Substrate for Peer-to-Peer Desktop Grid Computing based on Open Standards</i>. Journal of Grid Computing, 2010.</Fragment>}
      />
      <CvEntry
        date="2009"
        name={<Fragment>S. Schulz, W. Blochinger and M. Poths. <i>A Network Substrate for Peer-to-Peer Grid Computing beyond Embarrassingly Parallel Applications</i>. In Proc. of the International Conference on Communications and Mobile Computing (CMC 2009), Kunming, China, IEEE Computer Society.</Fragment>}
      />
      <CvEntry
        date="2005"
        name={<Fragment>Henning Fernau, Michael Kaufmann, Mathias Poths.
        <i>Comparing Trees Via Crossing Minimization</i>. FSTTCS 2005: 457-46</Fragment>}
      />
    </Fragment>
  );
}
