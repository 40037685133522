import React, { Fragment } from "react";
import CvEntry from "./CvEntry";

export default function ProfessionalExperience(props) {
  const stopPropagation = (e) => e.stopPropagation();
  return (
    <Fragment>
      <h2>Professional Experience</h2>
      <CvEntry
        date="since 08/2020"
        name="SIX Interbank Clearing"
        location="Zurich, Switzerland"
      >
        <p>
          Senior Software Engineer at SIX Interbank Clearing (SIC). SIC builds and operates a realtime payment platform
          on behalf of the Swiss National Bank, processing domestic and international payments.
        </p>
        <p>
          Main technologies: High performance, low latency development on JVM, Java, Spring Boot, OpenShift, Maven,
          ..., ongoing.
        </p>
      </CvEntry>
      <CvEntry
        date="07/2019 - 07/2020"
        name="Sabbatical / Long Distance Hiking"
        location="Europe, USA, New Zealand"
      >
        <p>
          Besides other travelling, embarked on a 3000 km hike through New Zealand (Te Araroa) over almost six months,
          including the outbreak of a pandemic and a long lockdown in Wellington. See our blog {" "}
          <a href="https://nature-is-calling.com" onClick={stopPropagation}>
            Nature Is Calling
          </a>{" "}
          for a glympse into our adventure.
        </p>
      </CvEntry>
      <CvEntry
        date="10/2018 - 06/2019"
        name="Contovista AG"
        location="Schlieren, Switzerland"
      >
        <p>
          Senior Software Engineer in a fixed term contract. Contovista offers
          white-label software and analytics services for financial
          institutions. I supported the development of the core enrichment
          engine, requirements engineering, the release management and some
          coaching of less experienced colleagues.
        </p>

        <p>
          Main technologies: Java, Spring Boot, jOOQ, Gradle, Angular, Docker
        </p>
      </CvEntry>
      <CvEntry
        date="09/2015 - 09/2018"
        name="Innovation Process Technology AG"
        location="Zug, Switzerland"
      >
        <p>
          IT-Architect. Multiple projects: <br />
          <b>4</b> - Design and development on event based integration platform
          on top of Apache Kafka on an OpenShift base private cloud platform.
          <br />
          <b>3</b> - Appway process automation project in the financial sector  
          covering solution design and implementation.
          <br />
          <b>2</b> - Requirements Engineering, Design and Implementation of a
          data migration for a major Swiss Re-Insurer.
          <br />
          <b>1</b> - Development Architecture for a continuous data integration
          between on premise core application and Microsoft Dynamics CRM in the
          cloud for a big Swiss health insurer.
          <br />
        </p>
        <p>
          Main technologies: Java, Apache Kafka, OpenShift, Appway, Camunda,
          Angular, Oracle Data Integrator, Jenkins
        </p>
      </CvEntry>
      <CvEntry
        date="11/2014 - 05/2015"
        name="Anivo.ch, Startup"
        location="Zurich, Switzerland"
      >
        <p>
          Co-Founder / Technical Architect / Full stack development.
          Building online insurance comparison and broker platform. 
        </p>
        <p>
          Main technologies: Java EE, JAX-RS, Angular JS, Wildfly, Ansible,
          Github, Jenkins, Docker, Ansible, Vagrant
        </p>
      </CvEntry>
      <CvEntry
        date="02/2009 - 07/2014"
        name="Accenture AG"
        location="Zurich, Switzerland"
      >
        <p>
          Team Lead / Technical Architect. Working on international projects in
          banking and insurance industries with software architecture and team
          leading responsibilities. Additionally working as a Java trainer for
          new joiners.
        </p>

        <p>
          Main technologies: Java / EE, Oracle Coherence, JSF, MDA, JPA,
          Spring-Framework, OSGI, Eclipse Plugin Development Environment (PDE)
          with SWT
        </p>
      </CvEntry>
      <CvEntry
        date="06/1999 - 01/2008"
        name="Credia Communications GmbH"
        location="Urbar, Germany"
      >
        <p>
          Co-founder and partner. Design, development and maintenance of
          internet projects, customer care and various management and accounting
          tasks.
        </p>
        <p>Main technologies: PHP, mySQL, JavaScript, AJAX, HTML</p>
      </CvEntry>
    </Fragment>
  );
}
