import React, { Fragment } from "react";
import Birds from "./Birds";

export default function ProfilePicture() {
  return (
    <Fragment>
      <div className="profile-image profile-image-bg" />
      <Birds />
      <div className="profile-image profile-image-fg" />
      <div className="content">
        <h1>Mathias Poths</h1>
        <h2>Software Engineer</h2>
        <div className="links">
          <a
            href="https://www.linkedin.com/in/poths/"
            className="icon-link"
            title="LinkedIn Profile"
          >
            <i className="fab fa-linkedin fa-2x"></i>
          </a>
          <a
            href="https://www.nature-is-calling.com"
            className="icon-link"
            title="Nature Is Calling Travel Blog"
          >
            <i className="fas fa-image fa-2x"></i>
          </a>
        </div>
      </div>
    </Fragment>
  );
};
