import React, { useState } from "react";

export default function CvEntry({ date, name, location, children }) {
  const [visible, setVisible] = useState(false);
  const hasDetail = children !== undefined;
  const toggleDetail = () => setVisible(hasDetail && !visible);

  const extraClasses = hasDetail ? "cv-entry-with-detail" : "";
  return (
    <div className={"cv-entry " + extraClasses} onClick={toggleDetail}>
      <span className="cv-date">{date}</span>
      {hasDetail &&
        (visible ? (
          <i
            className="fas fa-chevron-down"
            style={{ marginRight: "0.3em" }}
          ></i>
        ) : (
          <i
            className="fas fa-chevron-right"
            style={{ marginRight: "0.3em" }}
          ></i>
        ))}
      {hasDetail ? <b>{name}</b> : name}
      {location && <br />} {location}
      {visible && children}
    </div>
  );
}
