import React, { useState, useRef, useEffect } from "react";
import "./Birds.css";
import BIRDS from "vanta/dist/vanta.birds.min";

const Birds = (props) => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        BIRDS({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          backgroundAlpha: 0,
          birdSize: 1.4,
          wingSpan: 25.0,
          speedLimit: 3.0,
          separation: 45.0,
          alignment: 32.0,
          cohesion: 54.0,
          quantity: 2.0,
          color1: 0x8189af,
          color2: 0xc0c5d9,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);
  return <div ref={myRef} className="Birds"></div>;
};

export default Birds;
