import React, { Fragment } from "react";
import CvEntry from "./CvEntry";

export default function Technology(props) {
  return (
    <Fragment>
      <h2>Technological skills</h2>
      <CvEntry
        date="Current"
        name="Java, Spring Boot, Maven, Gradle, SQL, Apache Kafka,  Docker, Openshift, Kubernetes, Angular, React, JavaScript, Typescript,HTML, CSS"
      />
      <CvEntry
        date="Previous"
        name="Java EE, Python, PHP, JSF, JSP, jQuery, Swift, Objective-C, 
C++, C, Perl"
      />
    </Fragment>
  );
}
