import React, { Fragment } from "react";
import CvEntry from "./CvEntry";

export default function Language(props) {
  return (
    <Fragment>
      <h2>Language skills</h2>
      <CvEntry date="German" name="mother tongue" />
      <CvEntry date="English" name="fluent" />
    </Fragment>
  );
}
