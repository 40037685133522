import React from "react";
import "./App.css";
import "@fortawesome/fontawesome-free/js/all";
import ProfessionalExperience from "./components/ProfessionalExperience";
import Education from "./components/Education";
import Teaching from "./components/Teaching";
import Language from "./components/Languages";
import Technology from "./components/Technology";
import Publications from "./components/Publications";
import ProfilePicture from "./components/ProfilePicture";

function App() {
  const email = "mathias" + "@".toString() + "poths.com";
  return (
    <div className="App">
      <header className="left-column">
        <ProfilePicture />
      </header>
      <div className="right-column">
        <h2>Welcome</h2>
        <p className="cv-full-text">
        I'm Mathias Poths, a Software Engineer, living in Zurich, Switzerland.
        You can find my resume below. If you would like to get in touch with me,
        feel free to send me an email to <a href={"mailto:" + email}>
          {email}
        </a>{" "}
        or contact me on social media.
        </p>
        <ProfessionalExperience />
        <Education />
        <Teaching />
        <Language />
        <Technology />
        <Publications />
      </div>
    </div>
  );
}

export default App;
